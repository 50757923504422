import React from "react"
import { graphql } from "gatsby"
import { normalizeSponsorPremier } from "../helpers";
import { allRoutes } from "../helpers/routes"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Video from "../components/video"
import LinkBtn from "../components/linkBtn"
import GoogleMap from "../components/map"
import { 
  SocialLink,
  SocialDownload,
  SocialFacebook,
  SocialInstagram,
  SocialTwitter,
  SocialYoutube
} from "../assets/images"

export default function SponsorPremier({ data }) {
  const { 
    name, 
    logo, 
    tagline,
    video,
    description, 
    address, 
    city,
    state,
    zip,
    addressNotes, 
    phone, 
    email, 
    website,
    brochure,
    facebook,
    instagram,
    twitter,
    youtube
  } = normalizeSponsorPremier(data.allContentfulPremierSponsor.edges[0].node);

  return (
    <Layout>
      <header className="sponsor__header sponsor__header--premier">
        <div className="content-area sponsor__overview">
          {logo && (
            <LinkBtn className="sponsor__logo" to={website}>
              <Img fluid={logo} alt={`${name} logo`} />
            </LinkBtn>
          )}
          <div>
            <h1>{name}</h1>
            {tagline && <p className="sponsor__tagline">{tagline}</p>}
          </div>
        </div>
      </header>
      <div className="content-area">
        <article className="sponsor">
          <div className="sponsor-content">
            {video && <Video title="title" videoUrl={video} />}
            <div className="rich-text" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          {(address || addressNotes || phone || email || website) &&
            <div className="sponsor-sidebar">
              <h2>Contact Information</h2>
              {address && city && state && (
                <GoogleMap address={`${address} ${city} ${state}`} />
              )}
              {address && (
                <div className="sponsor__address">
                  <span>{address}</span>
                  {city && state && zip && (
                    <>
                      <span className="separator">{" "}•{" "}</span>
                      <span>{city}, {state} {zip}</span>
                    </>
                  )}
                </div>
              )}
              {addressNotes && <p className="sponsor__address-note">{addressNotes}</p>}
              <hr />
              {email && (
                <span className="contact">
                  <LinkBtn className="contact__email link--underline" to={`mailto:${email}`}>
                    {email}
                  </LinkBtn>
                </span>
              )}
              {email && phone && <span className="separator">{" "}•{" "}</span>}
              {phone && (
                <a className="contact contact__phone" href={`tel:${phone}`}>
                  {phone}
                </a>
              )}
              {(website || brochure || facebook || instagram || twitter || youtube) && (
                <ul className="social">
                  {website && (
                    <li>
                      <LinkBtn to={website} title="Website">
                        <SocialLink />
                        <span className="is-visually-hidden">
                          {website}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                  {brochure && (
                    <li>
                      <LinkBtn to={brochure} title="Brochure" download>
                        <SocialDownload />
                        <span className="is-visually-hidden">
                          {brochure}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                  {facebook && (
                    <li>
                      <LinkBtn to={facebook} title="Facebook">
                        <SocialFacebook />
                        <span className="is-visually-hidden">
                          {facebook}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                  {instagram && (
                    <li>
                      <LinkBtn to={instagram} title="Instagram">
                        <SocialInstagram />
                        <span className="is-visually-hidden">
                          {instagram}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                  {twitter && (
                    <li>
                      <LinkBtn to={twitter} title="Twitter">
                        <SocialTwitter />
                        <span className="is-visually-hidden">
                          {twitter}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                  {youtube && (
                    <li>
                      <LinkBtn to={youtube} title="YouTube">
                        <SocialYoutube />
                        <span className="is-visually-hidden">
                          {youtube}
                        </span>
                      </LinkBtn>
                    </li>
                  )}
                </ul>
              )}
            </div>
          }
        </article>
        <div className="cta-block-bottom">
          <LinkBtn className="btn" to={allRoutes.directory}>
            Back to the Directory
          </LinkBtn>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($name: String!) {
    allContentfulPremierSponsor(filter: {vendorName: {eq: $name}}) {
      edges {
        node {
          vendorName
          vendorLogo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          vendorTagline
          vendorVideo
          vendorDescription {
            childContentfulRichText {
              html
            }
          }
          vendorAddress {
            lat
            lon
          }
          vendorAddressStreet
          vendorAddressCity
          vendorAddressState
          vendorAddressZip
          vendorAddressNotes
          vendorPhoneNumber
          vendorEmailAddress
          vendorWebsite
          vendorBrochure {
            file {
              url
            }
          }
          vendorFacebookLink
          vendorInstagramLink
          vendorTwitterLink
          vendorYouTubeLink
        }
      }
    }
  }
`